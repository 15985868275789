var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `atmo-override-color atmo-entity-item-title ${_vm.cardCssClass} pr-2 position-relative`,
            "body-class": "purchase_part-body p-0",
            "border-variant": "light",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v("44-ФЗ")]
                          ),
                          _c("b-badge", { staticClass: "my-auto fs-14 mr-2" }, [
                            _vm._v("Вне ИС"),
                          ]),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "ExternalPurchaseShow",
                                  params: { id: _vm.contract.purchasable_id },
                                },
                                exact: "",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.contract.reg_number))]
                          ),
                          _vm.contract.purchase_object
                            ? [
                                _vm._v(
                                  ' На закупку "' +
                                    _vm._s(_vm.contract.purchase_object) +
                                    '"'
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-nowrap text-uppercase",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.contract.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "purchase_part-subheader" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { xs: "12", sm: "6", lg: "3" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Цена договора: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        _vm._s(_vm.$formatPrice(_vm.contract.start_price)) +
                          " руб."
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-0" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "3" } },
                [
                  _c("div", [
                    _vm.contract.status.id === "contract:active" ||
                    _vm.contract.status.id === "contract:fulfilled" ||
                    _vm.contract.status.id === "contract:unfulfilled" ||
                    _vm.contract.status.id === "contract:terminated"
                      ? _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-14 m-0 grey-color" }, [
                            _vm._v("Договор заключен:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-14 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract.purchase_data
                                        .contract_conclusion_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.contract.status.id === "contract:fulfilled" &&
                    _vm.contract.purchase_data.contract_execution_date
                      ? _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-14 m-0 grey-color" }, [
                            _vm._v("Договор исполнен:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-14 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract.purchase_data
                                        .contract_execution_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "3" } },
                [
                  _c("div", [
                    _c("div", { staticClass: "my-2" }, [
                      _c("p", { staticClass: "fs-14 grey-color m-0" }, [
                        _vm._v("Предмет закупки:"),
                      ]),
                      _c("p", { staticClass: "fs-14 grey-color fw-600" }, [
                        _vm._v(" " + _vm._s(_vm.contract.purchase_name) + " "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "my-2 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-14 grey-color m-0" }, [
                          _vm._v("Заказчик:"),
                        ]),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "system_link-style fw-600 text-uppercase fs-14",
                            attrs: {
                              to:
                                "/organizations/" +
                                _vm.contract.organization_id,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.contract.customer_full_name) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "my-2 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-14 grey-color m-0" }, [
                          _vm._v("Поставщик:"),
                        ]),
                        !_vm.contract.purchase_data.is_private_provider
                          ? [
                              _vm.contract.supplier_id
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "system_link-style fw-600 text-uppercase fs-14",
                                      attrs: {
                                        to:
                                          "/organizations/" +
                                          _vm.contract.supplier_id,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.contract.supplier_full_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _c("p", { staticClass: "fs-14 fw-600 m-0" }, [
                                _vm._v("Физическое лицо"),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "3" } },
                [
                  _c("div", [
                    _c("div", { staticClass: "my-2" }, [
                      _c("p", { staticClass: "fs-14 m-0 grey-color" }, [
                        _vm._v(" Количество позиций: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.contract.item_count)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "purchase_part-body_col atmo-column atmo-special-style",
                  attrs: { md: "3" },
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-2",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-search link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "ExternalPurchaseShow",
                                params: { id: _vm.contract.purchasable_id },
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Информация о закупке")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-2",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-shield link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "ExternalPurchaseShow",
                                params: { id: _vm.contract.purchasable_id },
                                hash: "#atmo-supplier-info",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Поставщик")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-2",
                      },
                      [
                        _c("i", {
                          staticClass: "atmo-icon-contract link-icon",
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "ExternalPurchaseShow",
                                params: { id: _vm.contract.purchasable_id },
                                hash: "#atmo-contract",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Договор")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }