<template>
    <b-col cols="12" :md="md" :lg="lg">
        <b-form-group>
            <multiselect v-model="text" :options="options" :multiple="true" :allow-empty="true" :close-on-select="true" :clear-on-select="false" :preserve-search="false" :show-labels="false" :placeholder="placeholder" :label="labelField" :track-by="trackBy">
                <span slot="noOptions">Нет данных.</span>
                <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
            </multiselect>
            <span class="fs-10 label-position" :class="labelClass">{{ label }}</span>
        </b-form-group>
    </b-col>
</template>

<script>
export default {
    name: 'filter-multiple-select',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        labelField: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        md: {
            type: String,
            default: '3'
        },
        lg: {
            type: String,
            default: '3'
        },
        labelClass: {
            type: String,
            default: 'halfWhite-color'
        }
    },
    computed: {
        text: {
            get() {
                return this.options ? this.options.filter((e) => this.value.includes(e[this.trackBy])) : [];
            },
            set(value) {
                this.$emit(
                    'input',
                    value.map((e) => e[this.trackBy])
                );
            }
        },
        id() {
            return 'filter-multiple-select_' + this._uid;
        }
    }
};
</script>
<style scoped>
.halfWhite-color {
    color: rgba(255, 255, 255, 0.5);
}

.label-position {
    display: block;
}

.category_menu li {
    padding-bottom: 9px;
}

.category_menu li + li {
    margin-left: 20px;
}

.category_menu li a {
    font-size: 14px;
    color: var(--grey-color);
    border-bottom: 2px solid transparent;
    text-decoration: none;
    padding-bottom: 9px;
    white-space: nowrap;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}

.mobile_category_menu li {
    padding: 10px 0;
}

.mobile_category_menu li + li {
    border-top: 1px solid var(--green-color);
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 767px) {
    .purchase_part-body_col > div::before {
        display: none;
    }
}

@media screen and (max-width: 414px) {
}

@media screen and (max-width: 320px) {
}
</style>
<style lang="less">
@subtext-color: #777;
@preloader-bg-color: rgba(255, 255, 255, 0.75);
@suggestions-bg-color: #fff;
@subtext-label-color: #f5f5f5;

.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}

.rounded(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.cross-button-mixin() {
    @cross-padding: 20;
    @cross-ray-width: 10;
    @cross-ray-length: 50 - @cross-padding - @cross-ray-width;
    @svg: "<svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'><g><path d='m@{cross-padding},@{cross-padding}m@{cross-ray-width},0l@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},@{cross-ray-length}l@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},@{cross-ray-width}l-@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},@{cross-ray-length}l-@{cross-ray-width},-@{cross-ray-width}l@{cross-ray-length},-@{cross-ray-length}l-@{cross-ray-length},-@{cross-ray-length}l@{cross-ray-width},-@{cross-ray-width}' fill='#999'/></g></svg>";
    @svg64: `new Buffer(@{svg}) .toString('base64') `;
    background: transparent url('data:image/svg+xml;base64,@{svg64}') 50% 50% no-repeat;
    background-size: contain;

    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}
.b-form-datepicker > button,
.b-form-datepicker > label {
    color: rgba(255, 255, 255, 0.7) !important;
}
</style>
